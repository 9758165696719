// import "core-js/stable";
// import "regenerator-runtime/runtime";
import SmoothScroll from 'smooth-scroll';
import Swiper from 'swiper/bundle';

const initPage = () => {
	SmoothScroll('a[href*="#"]', {
		speed: 500,
		speedAsDuration: true
	});

	const swiper = new Swiper(".swiper", {
		effect: 'fade',
		fadeEffect: {
		  crossFade: true
		},
		autoplay: {
		  delay: 3000,
		},
	});

	const fiAnime= Array.prototype.slice.call(document.body.querySelectorAll("._anime_in"), 0)
	const options = {
		rootMargin: "0px",
		threshold: [0]
	};
	const addfiAnime = (el) => {
		el.forEach(t => {
			if (t.intersectionRatio > 0) {
				const $t = t.target;
				$t.classList.add("_anime_show");
				isObserverAnime.unobserve($t);
			}
		});
	}
	const isObserverAnime = new IntersectionObserver(addfiAnime, options)
	fiAnime.forEach(el => {
		isObserverAnime.observe(el)
	});

	// Get all "toggle_nav" elements
	const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.toggle_nav'), 0);
	const navAtags = Array.prototype.slice.call(document.querySelectorAll('.okuni_mainnav a'));
	if ($navbarBurgers.length > 0) {
		$navbarBurgers.forEach(el => {
			el.addEventListener('click', () => {
				const target = el.dataset.target;
				const $target = document.getElementById(target);
				$target.classList.toggle('is-active');

			});
		});
	}
	if (navAtags.length > 0) {
		navAtags.forEach(el => {
			el.addEventListener('click', () => {
				const target = el.dataset.target;
				const $target = document.getElementById(target);
				$target.classList.remove('is-active');

			});
		})
	}

	//
	const openKey = Array.prototype.slice.call(document.querySelectorAll(".accordion_open"));
	const closeKey = Array.prototype.slice.call(document.querySelectorAll(".accordion_close"));
	if (openKey.length > 0) {
		openKey.forEach(el => {
			el.addEventListener("click", function() {
				const target = document.getElementById(this.dataset.rel);
				document.querySelectorAll(".accordion").forEach(el => {
					el.classList.remove("is-active");
				})
				if (target.length !== null) {
					target.classList.add("is-active");
				}
			})
		})
	}
	if (closeKey.length > 0) {
		closeKey.forEach(el => {
			el.addEventListener("click", function() {
				const target = document.getElementById(this.dataset.rel);
				if (target.length !== null) {
					target.classList.remove("is-active");
				}
			})
		})
	}
}

//init
if (document.readyState !== "loading") {
	initPage();
} else {
	document.addEventListener("DOMContentLoaded", () => {
		initPage();
	})
}

//css
// import 'swiper/css/bundle';
// import "../scss/styles.scss";